import { ChangeEvent, memo, useEffect, useState } from 'react';
import { useI18n } from 'AurionCR/components';
import { isToday, isValid } from 'date-fns';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { CleaveTime } from 'components/cleave/cleave';
import { addZero } from 'components/helpers';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DateView, DesktopDateTimePicker, DesktopDateTimePickerSlots, MobileDatePicker } from '@mui/x-date-pickers';
import Calendar from '@mui/icons-material/Event';
import CustomDatePickerToolBar from 'AurionCR/components/form/date/date-picker/components/app-date-picker-toolbar';
import { CustomDatePickerHeader } from 'AurionCR/components/form/date/components/custom-date-picker-header.tsx';
import style from './index.module.scss';
import { useOpen } from 'AurionCR/components/hooks';

function createFullTime(date: Date | string | null, time: string | null) {
  const currentDate = new Date();
  const today = date ? isToday(new Date(date)) : false;
  return date
    ? new Date(
      new Date(date ? date : '').setHours(
        time ? Number(time?.slice(0, 2)) || 0 : today ? currentDate.getHours() : 0,
        time ? Number(time?.slice(3)) || 0 : today ? currentDate.getMinutes() : 0,
      ),
    )
    : null;
}

type ITextFieldColor = 'error' | 'warning' | 'primary' | 'secondary' | 'info' | 'success';

interface IDateTimePicker extends Omit<DesktopDateTimePickerSlots<any>, 'renderInput'> {
  label?: string;
  value?: string | Date | null;
  error?: any | { message: string };
  format?: string;
  showTodayButton?: boolean;
  ampm?: boolean;
  helperText?: string;
  onChange: (value: {
    target: {
      value: Date | null;
    };
  }) => void;
  disabled?: boolean;
}

export const DateTimePicker = memo(
  ({
     value,
     error,
     showTodayButton = true,
     ampm = false,
     format = 'dd/MM/yyyy',
     helperText,
     ...props
   }: IDateTimePicker) => {
    const { t } = useI18n();
    const [date, setDate] = useState<string | null | Date>(null);
    const [time, setTime] = useState<string | null>(null);
    const [dateView, setDateView] = useState<DateView>('day');
    const { isOpen, handleOpen, handleClose } = useOpen();

    const { onChange, ...rest } = props;

    useEffect(() => {
      if (value) {
        if (typeof value === 'string' && isValid(new Date(value))) {
          setDate(new Date(value).toDateString());
          setTime(
            addZero(new Date(value).getHours()) + ':' + addZero(new Date(value).getMinutes()),
          );
        } else if (
          value instanceof Date &&
          isValid(new Date(value.toDateString()))
        ) {
          setDate(value.toDateString());
          setTime(addZero(value.getHours()) + ':' + addZero(value.getMinutes()));
        }
      }
    }, [value, setDate, setTime]);

    const commonOptions = {
      value: date ? new Date(date) : null,
      format: format,
      onChange: (value: Date | null) => {
        if (!value) setTime(null);
        setDate(value);
      },
    };
    const onChange_ = () => {
      onChange &&
      onChange({
        target: {
          value: createFullTime(date, time),
        },
      });
    };

    useEffect(() => {
      onChange_();
    }, [date]);

    return (
      <div className={'date-time-field'}>
        <div className={'date'}>
          <DesktopDateTimePicker
            {...rest}
            {...commonOptions}
            open={false}
            onOpen={handleOpen}
            slotProps={{
              textField: {
                error: !!error,
                color: (error ? 'warning' : 'primary') as ITextFieldColor,
                fullWidth: true,
                InputProps: {
                  endAdornment: <Calendar color={'disabled'} onClick={handleOpen} />,
                },
              },
            }}
          />
          {isOpen && (
            <MobileDatePicker
              {...rest}
              {...commonOptions}
              open={isOpen}
              view={dateView}
              onViewChange={(view) => setDateView(view)}
              onClose={handleClose}
              slots={{
                toolbar: (props) => (
                  <CustomDatePickerToolBar {...props} setOpenView={setDateView} />
                ),
                calendarHeader: CustomDatePickerHeader,
              }}
              slotProps={{
                mobilePaper: {
                  className: style.paper,
                },
                textField: {
                  sx: {
                    display: 'none',
                  },
                },
                actionBar: {
                  actions: ['today', 'cancel', 'accept'],
                },
              }}
              localeText={{
                cancelButtonLabel: t('cancel'),
                todayButtonLabel: t('today'),
                okButtonLabel: t('ok'),
              }}
            />
          )}
        </div>
        <div className={'time'}>
          <TextField
            label={''}
            value={time || ''}
            disabled={!date || props?.disabled}
            error={Boolean(error)}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setTime(event.currentTarget.value);
            }}
            className={'datePickerTime'}
            onBlur={onChange_}
            InputProps={{
              inputComponent: CleaveTime,
              endAdornment: (
                <InputAdornment position={'end'}>
                  <AccessTimeIcon color={'disabled'} />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {Boolean(error?.message) && (
          <Typography fontSize={12} color={'error'} className={'MuiFormHelperText-root Mui-error'}>
            {t(error.message)}
          </Typography>
        )}
      </div>
    );
  },
);
